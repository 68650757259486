import { createSearchParams, useNavigate, useParams, useSearchParams } from "react-router-dom";
import ava1 from "../../assets/images/noAva.png";
import ava2 from "../../assets/images/rank2.png";
import ava3 from "../../assets/images/rank3.png";
import ava6 from "../../assets/images/rank6.png";
import ava13 from "../../assets/images/vid7.png";
import ava5 from "../../assets/images/vid6.png";
import ava14 from "../../assets/images/vid5.png";
import ava12 from "../../assets/images/vid4.png";
import ava9 from "../../assets/images/vid3.png";
import ava4 from "../../assets/images/vid2.png";
import ava7 from "../../assets/images/vid1.png";
import { FaChevronDown } from "react-icons/fa";
import { Empty } from "antd";

import * as cts from "../../constants/ui/svg";
import i18n from "locales/i18n";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import BreadcrumbComponent from "components/Common/Breadcrumb";
const RankingPage = () => {
  const navigate = useNavigate();
  const [tabFirst, setTabFirst] = useState(1);
  const [tabSecond, setTabSecond] = useState(1);
  const [type, setType] = useState(1);
  const [region, setRegion] = useState(0);
  const [time, setTime] = useState(0);
  const [sort, setSort] = useState(i18n.t("NATIONWIDE"));
  const [visibleSortOption, setVisibleSortOption] = useState(false);
  const [activeTab, setActiveTab] = useState("NUMBER_OF_VIEW");
  const arr = [
    {
      id: 1,
      rank: 1,
      top: cts.rank1,
      ava: ava1,
      live: true,
      name: "ハルト",
      region: "北海道エリア ",
      numberView: 21543,
      follow: false,
    },
    {
      id: 2,
      rank: 2,
      top: cts.rank2,
      ava: ava2,
      live: false,
      name: "ハルト",
      region: "北海道エリア ",
      numberView: 21543,
      follow: true,
    },
    {
      id: 3,
      rank: 3,
      top: cts.rank3,
      ava: ava3,
      live: false,
      name: "ハルト",
      region: "北海道エリア ",
      numberView: 21543,
      follow: false,
    },
    {
      id: 4,
      rank: 4,
      top: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[18px] notosans_bold">4</span>
          <span className="bg_ranking">{cts.bgRank}</span>
        </div>
      ),
      ava: ava4,
      live: false,
      name: "ハルト",
      region: "北海道エリア ",
      numberView: 21543,
      follow: false,
    },
    {
      id: 5,
      rank: 5,
      top: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[18px] notosans_bold">5</span>
          <span className="bg_ranking">{cts.bgRank}</span>
        </div>
      ),
      ava: ava5,
      live: false,
      name: "ハルト",
      region: "北海道エリア ",
      numberView: 21543,
      follow: false,
    },
    {
      id: 6,
      rank: 6,
      top: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[18px] notosans_bold">6</span>
          <span className="bg_ranking">{cts.bgRank}</span>
        </div>
      ),
      ava: ava6,
      live: false,
      name: "ハルト",
      region: "北海道エリア ",
      numberView: 21543,
      follow: false,
    },
    {
      id: 7,
      rank: 7,
      top: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[18px] notosans_bold">7</span>
          <span className="bg_ranking">{cts.bgRank}</span>
        </div>
      ),
      ava: ava7,
      live: false,
      name: "ハルト",
      region: "北海道エリア ",
      numberView: 21543,
      follow: false,
    },
    {
      id: 8,
      rank: 8,
      top: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[18px] notosans_bold">8</span>
          <span className="bg_ranking">{cts.bgRank}</span>
        </div>
      ),
      ava: ava1,
      live: false,
      name: "ハルト",
      region: "北海道エリア ",
      numberView: 21543,
      follow: false,
    },
    {
      id: 9,
      rank: 9,
      top: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[18px] notosans_bold">9</span>
          <span className="bg_ranking">{cts.bgRank}</span>
        </div>
      ),
      ava: ava9,
      live: true,
      name: "ハルト",
      region: "北海道エリア ",
      numberView: 21543,
      follow: false,
    },
    {
      id: 10,
      rank: 10,
      top: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[18px] notosans_bold">10</span>
          <span className="bg_ranking">{cts.bgRank}</span>
        </div>
      ),
      ava: ava1,
      live: false,
      name: "ハルト",
      region: "北海道エリア ",
      numberView: 21543,
      follow: false,
    },
    {
      id: 11,
      rank: 11,
      top: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[18px] notosans_bold">11</span>
          <span className="bg_ranking">{cts.bgRank}</span>
        </div>
      ),
      ava: ava1,
      live: false,
      name: "ハルト",
      region: "北海道エリア ",
      numberView: 21543,
      follow: false,
    },
    {
      id: 12,
      rank: 12,
      top: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[18px] notosans_bold">12</span>
          <span className="bg_ranking">{cts.bgRank}</span>
        </div>
      ),
      ava: ava12,
      live: false,
      name: "ハルト",
      region: "北海道エリア ",
      numberView: 21543,
      follow: false,
    },
    {
      id: 13,
      rank: 13,
      top: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[18px] notosans_bold">13</span>
          <span className="bg_ranking">{cts.bgRank}</span>
        </div>
      ),
      ava: ava13,
      live: false,
      name: "ハルト",
      region: "北海道エリア ",
      numberView: 21543,
      follow: false,
    },
    {
      id: 14,
      rank: 14,
      top: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[18px] notosans_bold">14</span>
          <span className="bg_ranking">{cts.bgRank}</span>
        </div>
      ),
      ava: ava14,
      live: true,
      name: "ハルト",
      region: "北海道エリア ",
      numberView: 21543,
      follow: false,
    },
    {
      id: 15,
      rank: 15,
      top: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[18px] notosans_bold">15</span>
          <span className="bg_ranking">{cts.bgRank}</span>
        </div>
      ),
      ava: ava1,
      live: false,
      name: "ハルト",
      region: "北海道エリア ",
      numberView: 21543,
      follow: false,
    },
  ];
  const arrLink = [
    { id: 0, name: i18n.t("NATION_WIDE") },
    { id: 1, name: i18n.t("REGION1") },
    { id: 2, name: i18n.t("REGION2") },
    { id: 3, name: i18n.t("REGION3") },
    { id: 4, name: i18n.t("REGION4") },
    { id: 5, name: i18n.t("REGION5") },
    { id: 6, name: i18n.t("REGION6") },
    { id: 7, name: i18n.t("REGION7") },
  ];
  const tabs = [
    { label: i18n.t("NUMBER_OF_VIEW"), type: "NUMBER_OF_VIEW" },
    { label: i18n.t("GIFT_NUMBER"), type: "GIFT_NUMBER" },
  ];
  const arrTime = [{ time: i18n.t("DAYS") }, { time: i18n.t("WEEK") }, { time: i18n.t("MONTHLY") }];
  const rankingArr = [
    {
      id: 0,
      top: cts.rank1,
      ranking: (
        <span className="icon_rank_size27 max-500px:w-6 max-500px:h-6 max-500px:flex max-500px:justify-center max-500px:items-center">
          {cts.rank1}
        </span>
      ),
      avatar: ava4,
      name: "ニックネーム",
      live: true,
      region: "北海道エリア ",
      numberView: 21543,
      follow: false,
    },

    {
      id: 1,
      top: cts.rank1,
      ranking: (
        <span className="icon_rank_size27 max-500px:w-6 max-500px:h-6 max-500px:flex max-500px:justify-center max-500px:items-center">
          {cts.rank2}
        </span>
      ),
      avatar: ava1,
      name: "ニックネーム",
      live: true,
      region: "北海道エリア ",
      numberView: 21543,
      follow: false,
    },

    {
      id: 2,
      top: cts.rank1,
      ranking: (
        <span className="icon_rank_size27 max-500px:w-6 max-500px:h-6 max-500px:flex max-500px:justify-center max-500px:items-center">
          {cts.rank3}
        </span>
      ),
      avatar: ava2,
      name: "ニックネーム",
      live: true,
      region: "北海道エリア ",
      numberView: 21543,
      follow: false,
    },
    {
      id: 3,
      top: cts.rank1,
      ranking: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[15px] notosans_medium">4</span>
          <span className="icon_rank_size33">{cts.bgRank}</span>
        </div>
      ),
      avatar: ava3,
      name: "ニックネーム",
      live: true,
      region: "北海道エリア ",
      numberView: 21543,
      follow: false,
    },
    {
      id: 4,
      top: cts.rank1,
      ranking: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[15px] notosans_medium">5</span>
          <span className="icon_rank_size33">{cts.bgRank}</span>
        </div>
      ),
      avatar: ava4,
      name: "ニックネーム",
      live: true,
      region: "北海道エリア ",
      numberView: 21543,
      follow: false,
    },
    {
      id: 5,
      top: cts.rank1,
      ranking: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[15px] notosans_medium">6</span>
          <span className="icon_rank_size33">{cts.bgRank}</span>
        </div>
      ),
      avatar: ava5,
      name: "ニックネーム",
      live: true,
      region: "北海道エリア ",
      numberView: 21543,
      follow: false,
    },
    {
      id: 6,
      top: cts.rank1,
      ranking: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[15px] notosans_medium">7</span>
          <span className="icon_rank_size33">{cts.bgRank}</span>
        </div>
      ),
      avatar: ava6,
      name: "ニックネーム",
      live: true,
      region: "北海道エリア ",
      numberView: 21543,
      follow: false,
    },

    {
      id: 7,
      top: cts.rank1,
      ranking: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[15px] notosans_medium">8</span>
          <span className="icon_rank_size33">{cts.bgRank}</span>
        </div>
      ),
      avatar: ava7,
      name: "ニックネーム",
      live: true,
      region: "北海道エリア ",
      numberView: 21543,
      follow: false,
    },
    {
      id: 8,
      top: cts.rank1,
      ranking: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[15px] notosans_medium">9</span>
          <span className="icon_rank_size33">{cts.bgRank}</span>
        </div>
      ),
      avatar: ava13,
      name: "ニックネーム",
      live: true,
      region: "北海道エリア ",
      numberView: 21543,
      follow: false,
    },
    {
      id: 9,
      top: cts.rank1,
      ranking: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[15px] notosans_medium">10</span>
          <span className="icon_rank_size33">{cts.bgRank}</span>
        </div>
      ),
      avatar: ava14,
      name: "ニックネーム",
      live: true,
      region: "北海道エリア ",
      numberView: 21543,
      follow: false,
    },
    {
      id: 10,
      top: cts.rank1,
      ranking: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[15px] notosans_medium">11</span>
          <span className="icon_rank_size33">{cts.bgRank}</span>
        </div>
      ),
      avatar: ava1,
      name: "ニックネーム",
      live: true,
      region: "北海道エリア ",
      numberView: 21543,
      follow: false,
    },
  ];
  const sortOptions = [
    { name: i18n.t("NATIONWIDE") },
    { name: i18n.t("HOKKAIDO") },
    { name: i18n.t("TOHOKU") },
    { name: i18n.t("KANTO") },
    { name: i18n.t("CHUBU") },
    { name: i18n.t("KINKI") },
    { name: i18n.t("CHINA/SHIKOKU") },
    { name: i18n.t("KYUSHU/OKINAWA") },
  ];
  return (
    <div className="flex justify-center pt-[10px] pb-[40px] mt-[2px]">
      <div className="size_content_hd flex-col flex w-full">
        <div className="max-500px:hidden">
          <BreadcrumbComponent breadMain={i18n.t("RANKING")} />
        </div>
        <div className="mt-[10px]   mb-3 flex justify-between items-center">
          <span className="text-[#101828]  text-[24px] notosans_bold mb-3 max-500px:text-[18px] flex items-center">
            {i18n.t("RANKING")}
          </span>
          <button
            onClick={() => setVisibleSortOption((x) => !x)}
            className="border border-[#D0D5DD] bg-white flex items-center justify-between rounded-lg px-3 w-[140px] py-2"
          >
            <span className="text-[#667085] text-base notosans_normal ">{sort}</span>
            <FaChevronDown color="#EF6820" size={12} />
          </button>
        </div>
        {/* <div className="flex items-center justify-center notosans_thin pb-[100px] w-full">
          {i18n.t("NO_DATA")}
        </div> */}
        <div className="items-center grid grid-cols-2 gap-4 max-800px:grid-cols-1">
          <div className="flex flex-col border border-[rgba(0,0,0,0.1)] rounded-[8px] bg-white shadow-[0px_1px_3px_0px_#1018281A]">
            <div className="flex items-center justify-between px-[14px] h-[50px] bg-[#EF6820] rounded-t-[8px]  max-500px:relative">
              {visibleSortOption && (
                <div className="absolute -top-2 right-0  bg-white  flex-col gap-2 rounded-lg border border-[#EAECF0] p-2 w-[140px] hidden max-500px:flex">
                  {sortOptions.map((option: any, index: any) => {
                    return (
                      <button
                        onClick={() => {
                          setSort(option.name);
                          setVisibleSortOption(false);
                        }}
                        key={index}
                        className="w-full flex items-center rounded-lg hover:bg-[#FEF6EE] notosans_normal text-base py-2 px-3"
                      >
                        {option.name}
                      </button>
                    );
                  })}
                </div>
              )}

              <span className="flex items-center text-white text-[18px] notosans_bold">
                <span className="mr-[8px]">{cts.deliverIcon1}</span>
                {i18n.t("DELIVERY_TIME")}
              </span>

              <span className="flex items-center text-white text-[16px] notosans_normal">
                {i18n.t("NATIONWIDE")}
              </span>
            </div>
            <div className=" items-center grid grid-cols-3">
              <div
                onClick={() => setTabFirst(1)}
                className={`${
                  tabFirst === 1
                    ? "border-[#EF6820] text-[#EF6820] border-b-[2px] notosans_bold"
                    : "border-[rgba(0,0,0,0.1)] text-[#98A2B3] border-b"
                } text-[16px] notosans_normal h-[40px] flex items-center justify-center cursor-pointer`}
              >
                {i18n.t("DAYS")}
              </div>
              <div
                onClick={() => setTabFirst(2)}
                className={`${
                  tabFirst === 2
                    ? "border-[#EF6820] text-[#EF6820] border-b-[2px] notosans_bold"
                    : "border-[rgba(0,0,0,0.1)] text-[#98A2B3] border-b"
                } text-[16px] notosans_normal h-[40px] flex items-center justify-center cursor-pointer`}
              >
                {i18n.t("WEEK")}
              </div>
              <div
                onClick={() => setTabFirst(3)}
                className={`${
                  tabFirst === 3
                    ? "border-[#EF6820] text-[#EF6820] border-b-[2px] notosans_bold"
                    : "border-[rgba(0,0,0,0.1)] text-[#98A2B3] border-b"
                } text-[16px] notosans_normal h-[40px] flex items-center justify-center cursor-pointer`}
              >
                {i18n.t("MONTHLY")}
              </div>
            </div>
            <div className="pt-[12px] flex flex-col pb-[8px] h-[756px] pr-[4px]">
              <span className="text-[#667085] ml-[16px] notosans_normal mb-[12px]">
                {i18n.t("DAILY_RANKING_UPDATE")}
              </span>
              {/* <div className="rank_scroll overflow-y-auto flex flex-col">
                {rankingArr?.map((x: any, y: any) => {
                  return (
                    <div
                      key={y}
                      className="py-[16px] flex items-center justify-between pl-[16px] pr-[12px]  max-500px:border-b-[1px] max-500px:border-[#EAECF0]"
                    >
                      <div className="flex items-center ">
                        {x.ranking}
                        <img
                          className="h-[76px] w-[76px] rounded-[50%] object-cover ml-[16px] mr-[12px] max-500px:h-10 max-500px:w-10 max-500px:mx-3"
                          src={x.avatar}
                        />
                        <div className="flex flex-col justify-between ml-[16px] h-[76px] max-500px:ml-0">
                          <div className="flex items-center">
                            <span className="text-[#182230] text-[16px] max-1200px:text-[14px] notosans_bold  max-500px:text-sm line-clamp-1">
                              {x.name}
                            </span>
                            {x.live === true && <span className="ml-[8px]">{cts.live1Icon}</span>}
                          </div>
                          <span className="flex items-center">
                            {cts.location1Icon}
                            <span className="text-[#182230] text-[14px] max-1200px:text-[12px] notosans_normal ml-[6px] ">
                              {x.region}
                            </span>
                          </span>
                          <span className="flex items-center text-[#667085] max-1200px:text-[14px] text-[16px] notosans_normal">
                            {i18n.t("DELIVERY_TIME")} :
                            <span className="notosans_medium text-[#EF6820] ml-[5px]">1:23:07</span>
                          </span>
                        </div>
                      </div>
                      {x.follow === true ? (
                        <div className="px-[12px] h-[36px] bg-[#EAECF0] cursor-pointer rounded-[8px] flex items-center justify-center">
                          <span className="text-[#475467] text-[14px] max-1200px:text-[12px] notosans_medium ">
                            {i18n.t("FOLLOWED")}
                          </span>
                        </div>
                      ) : (
                        <div className="px-[12px] h-[36px] cursor-pointer bg-white border border-[#EF6820] rounded-[8px] flex items-center justify-center min-w-max">
                          <span className="mr-[6px]">{cts.heartRed}</span>
                          <span className="text-[#EF6820] text-[14px] max-1200px:text-[12px] notosans_medium">
                            {i18n.t("FOLLOW")}
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div> */}
              <div className="flex justify-center items-center w-full pb-[20px]">
                <Empty />
              </div>
            </div>
          </div>
          <div className="flex flex-col border border-[rgba(0,0,0,0.1)] rounded-[8px] bg-white shadow-[0px_1px_3px_0px_#1018281A]">
            <div className="flex items-center justify-between px-[14px] h-[50px] bg-[#EF6820] rounded-t-[8px] relative">
              {visibleSortOption && (
                <div className="absolute -top-2 right-0  bg-white  flex-col gap-2 rounded-lg border border-[#EAECF0] p-2 w-[140px] max-500px:hidden">
                  {sortOptions.map((option: any, index: any) => {
                    return (
                      <button
                        onClick={() => {
                          setSort(option.name);
                          setVisibleSortOption(false);
                        }}
                        key={index}
                        className="w-full flex items-center rounded-lg hover:bg-[#FEF6EE] notosans_normal text-base py-2 px-3"
                      >
                        {option.name}
                      </button>
                    );
                  })}
                </div>
              )}

              <span className="flex items-center text-white text-[18px] notosans_bold">
                <span className="mr-[8px] icon_size_24">{cts.coin2}</span>
                {i18n.t("POINT")}
              </span>
              <span className="flex items-center text-white text-[16px] notosans_normal">
                {i18n.t("NATIONWIDE")}
              </span>
            </div>
            <div className=" items-center grid grid-cols-3">
              <div
                onClick={() => setTabSecond(1)}
                className={`${
                  tabSecond === 1
                    ? "border-[#EF6820] text-[#EF6820] border-b-[2px] notosans_bold"
                    : "border-[rgba(0,0,0,0.1)] text-[#98A2B3] border-b"
                } text-[16px] notosans_normal h-[40px] flex items-center justify-center cursor-pointer`}
              >
                {i18n.t("DAYS")}
              </div>
              <div
                onClick={() => setTabSecond(2)}
                className={`${
                  tabSecond === 2
                    ? "border-[#EF6820] text-[#EF6820] border-b-[2px] notosans_bold"
                    : "border-[rgba(0,0,0,0.1)] text-[#98A2B3] border-b"
                } text-[16px] notosans_normal h-[40px] flex items-center justify-center cursor-pointer`}
              >
                {i18n.t("WEEK")}
              </div>
              <div
                onClick={() => setTabSecond(3)}
                className={`${
                  tabSecond === 3
                    ? "border-[#EF6820] text-[#EF6820] border-b-[2px] notosans_bold"
                    : "border-[rgba(0,0,0,0.1)] text-[#98A2B3] border-b"
                } text-[16px] notosans_normal h-[40px] flex items-center justify-center cursor-pointer`}
              >
                {i18n.t("MONTHLY")}
              </div>
            </div>
            <div className="pt-[12px] flex flex-col pb-[8px] h-[756px] pr-[4px]">
              <span className="text-[#667085] ml-[16px] notosans_normal mb-[12px]">
                {i18n.t("DAILY_RANKING_UPDATE")}
              </span>
              <div className="rank_scroll overflow-y-auto flex flex-col">
                {/* {rankingArr?.map((x: any, y: any) => {
                  return (
                    <div
                      key={y}
                      className="py-[16px] flex items-center justify-between pl-[16px] pr-[12px] border-b-[1px] border-[#EAECF0]"
                    >
                      <div className="flex items-center">
                        {x.ranking}
                        <img
                          className="h-[76px] w-[76px] rounded-[50%] ml-[16px] mr-[12px] object-cover  max-500px:h-10 max-500px:w-10 max-500px:mx-3"
                          src={x.avatar}
                        />
                        <div className="flex flex-col justify-between ml-[16px] h-[76px] max-500px:ml-0">
                          <div className="flex items-center">
                            <span className="text-[#182230] text-[16px] max-1200px:text-[14px] notosans_bold  max-500px:text-sm line-clamp-1">
                              {x.name}
                            </span>
                            {x.live === true && <span className="ml-[8px]">{cts.live1Icon}</span>}
                          </div>
                          <span className="flex items-center">
                            {cts.location1Icon}
                            <span className="text-[#182230] text-[14px] max-1200px:text-[12px] notosans_normal ml-[6px] ">
                              {x.region}
                            </span>
                          </span>
                          <span className="flex items-center text-[#EF6820] max-1200px:text-[14px] text-[16px] notosans_medium">
                            {x.numberView}
                            <span className="notosans_normal text-[#667085] ml-[5px]">
                              {cts.coinMini}
                            </span>
                          </span>
                        </div>
                      </div>
                      {x.follow === true ? (
                        <div className="px-[12px] h-[36px] bg-[#EAECF0] cursor-pointer rounded-[8px] flex items-center justify-center">
                          <span className="text-[#475467] text-[14px] max-1200px:text-[12px] notosans_medium ">
                            {i18n.t("FOLLOWED")}
                          </span>
                        </div>
                      ) : (
                        <div className="px-[12px] h-[36px] cursor-pointer bg-white border border-[#EF6820] rounded-[8px] flex items-center justify-center min-w-max">
                          <span className="mr-[6px]">{cts.heartRed}</span>
                          <span className="text-[#EF6820] text-[14px] max-1200px:text-[12px] notosans_medium">
                            {i18n.t("FOLLOW")}
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })} */}
                <div className="flex justify-center items-center w-full pb-[20px]">
                  <Empty />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RankingPage;
