import { Slider } from "@material-ui/core";
import { Modal, Tabs } from "antd";
import { useAppSelector } from "app/hooks";
import BreadcrumbComponent from "components/Common/Breadcrumb";
import i18n from "locales/i18n";
import { useCallback, useEffect, useRef, useState } from "react";
import Cropper from "react-easy-crop";
import { useNavigate } from "react-router-dom";
import getCroppedImg from "../../components/Common/CropImage";
import * as cts from "../../constants/ui/svg";
import TabCoinHistory from "./component/coinHistory";
import TabPassword from "./component/password";
import TabPaymentHistory from "./component/paymentHistory";
import TabProfile from "./component/profile";

const MyPage = () => {
  const navigator = useNavigate();
  const [tab, setTab] = useState(Number(localStorage.getItem("tabMyPage")) || 1);
  const [fileUpload, setFileUpload] = useState<any>();
  const [fileUploadAva, setFileUploadAva] = useState<any>();
  const [fileDataURL, setFileDataURL] = useState<any>();
  const [fileDataURLAva, setFileDataURLAva] = useState<any>();
  const userInfo = useAppSelector((state) => state.users.userInfo);
  const [openModalImg, setOpenModalImg] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage1, setCroppedImage1] = useState<any>(null);
  const [rotation, setRotation] = useState(0);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedImage, setCroppedimage] = useState<any>(null);

  const arrTab = [
    { name: i18n.t("PROFILE"), children: <TabProfile /> },
    { name: i18n.t("PASSWORD"), children: <TabPassword /> },
    { name: i18n.t("PAYMENT_HISTORY"), children: <TabPaymentHistory /> },
    { name: i18n.t("COIN_HISTORY"), children: <TabCoinHistory /> },
  ];
  const changeHandler = (e: any) => {
    e.preventDefault();
    const file = e.target.files[0];
    // if (!file?.type?.match(imageMimeType)) {
    //   alert('Image mime type is not valid');
    //   return;
    // }
    setFileUpload(file);
  };
  useEffect(() => {
    let fileReader = new FileReader(),
      isCancel = false;
    if (fileUploadAva) {
      fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURLAva(result);
        }
      };
      fileReader.readAsDataURL(fileUploadAva);
    }

    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [fileUploadAva]);
  useEffect(() => {
    let fileReader = new FileReader(),
      isCancel = false;
    if (fileUpload) {
      fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(fileUpload);
    }

    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [fileUpload]);
  //catch component unmounted
  useEffect(() => {
    return () => {
      localStorage.removeItem("tabMyPage");
    };
  }, []);
  const showCroppedImageProduct = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(fileDataURL, croppedAreaPixels, rotation);
      setCroppedImage1(croppedImage);

      if (fileUpload) {
        setOpenModalImg(false);
      }
      //  else {
      //   errorToast('変化なし');
      // }
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);
  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const changeHandlerImg = (e: any) => {
    const file = e.target.files[0];
    // if (!file?.type?.match(imageMimeType)) {
    //   alert('Image mime type is not valid');
    //   return;
    // }
    setFileUploadAva(file);
  };
  return (
    <div className="flex justify-center max-500px:bg-white border-[#D0D5DD] border-t max-500px:justify-normal max-500px:relative">
      <div className="size_content_hd sm:p-0 sm:mt-[10px] w-full pt-[10px] pb-[40px] max-500px:pt-3 ">
        <div className="max-500px:hidden my-[10px]">
          <BreadcrumbComponent breadMain={i18n.t("MY_ACCOUNT")} />
        </div>
        <span className="max-500px:flex notosans_bold text-[24px] max-500px:text-[18px] sm:ml-[20px]">
          {i18n.t("MY_ACCOUNT")}
        </span>
        <div className="">
          <div className="pt-[12px] pb-[24px] flex flex-col max-500px:py-0">
            <div className="h-[54px] w-full flex border-b border-[#D0D5DD] items-center max-500px:h-auto ">
              {arrTab.map((x, y) => {
                return (
                  <span
                    key={y}
                    onClick={() => {
                      const tabNumber = (y + 1) as number;
                      setTab(tabNumber);
                      localStorage.setItem("tabMyPage", JSON.stringify(tabNumber));
                    }}
                    className={`${
                      tab === y + 1
                        ? "text-[#EF6820] border-[#EF6820] border-b-[2px] "
                        : "text-[#98A2B3] hover:border-[#98A2B3] hover:border-b-[2px]"
                    } transition ease-in-out delay-150 translate-y-[1px] text-[20px] max-800px:text-[16px] h-full flex items-center notosans_bold mr-[20px] cursor-pointer max-500px:text-sm max-500px:mr-0 max-500px:flex-1 max-500px:justify-center max-500px:py-2`}
                  >
                    {x.name}
                  </span>
                );
              })}
            </div>
          </div>
          {tab === 1 && <TabProfile />}
          {tab === 2 && <TabPassword />}
          {tab === 3 && <TabPaymentHistory />}
          {tab === 4 && <TabCoinHistory />}
        </div>

        {/* <div className="hidden max-500px:flex profile_tabs w-[100vw]">
          <Tabs
            defaultActiveKey="0"
            style={{ width: "100%", overflowX: "scroll", display: "flex" }}
            items={arrTab.map((item, index) => {
              return {
                label: item.name,
                key: String(index),
                children: item.children,
              };
            })}
          />
   
        </div> */}
        <Modal
          centered
          open={openModalImg}
          // onOk={() => setOpenModalImg(false)}
          // onCancel={() => setOpenModalImg(false)}
          className="modal_open_img"
          footer={[
            <>
              <div className="controls_1 flex flex-col !right-[264px]">
                <div className="flex items-center">
                  <span
                    onClick={() => {
                      const count = zoom - 0.4;
                      setZoom(count);
                    }}
                    className="text-[20px] mplus1pextrabold mr-[10px] cursor-pointer"
                  >
                    {" "}
                    -{" "}
                  </span>
                  <Slider
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    className=""
                    onChange={(e, zoom: any) => setZoom(zoom)}
                  />
                  <span
                    onClick={() => {
                      const count = zoom + 0.4;
                      setZoom(count);
                    }}
                    className="text-[20px] mplus1pextrabold ml-[10px] cursor-pointer"
                  >
                    {" "}
                    +
                  </span>
                </div>
              </div>
              <div className="flex !absolute items-center !top-[90%] ml-[274px]">
                <span className="h-[30px] w-[30px] mr-[20px] bg-[rgba(0,0,0,0.2)] flex items-center justify-center rounded-[15px]">
                  {cts.iconCropImg}
                </span>
                <span className="h-[30px] w-[30px] bg-[rgba(0,0,0,0.2)] flex items-center justify-center rounded-[15px]">
                  {cts.iconMoveImg}
                </span>
              </div>
              <div className="!absolute flex items-center w-[200px] !top-[100%] ml-[220px]">
                <span
                  onClick={showCroppedImageProduct}
                  className="cursor-pointer h-[41px] border border-black rounded-[10px] bg-[#3E6100] text-white flex items-center justify-center w-full px-[10px] mr-[10px]"
                >
                  保存する
                </span>
                <span
                  onClick={() => {
                    setOpenModalImg(false);
                    setFileUpload(null);
                    setCroppedImage1(null);
                  }}
                  className="cursor-pointer h-[41px] border border-black rounded-[10px] bg-[#DCBA78] flex items-center justify-center w-full px-[10px]"
                >
                  キャンセル
                </span>
              </div>
            </>,
          ]}
        >
          <div className=" flex flex-col">
            <div className="crop_img_product">
              <Cropper
                image={fileDataURL}
                crop={crop}
                zoom={zoom}
                aspect={4 / 3}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
          </div>
          {croppedImage && (
            <img src={croppedImage} className="h-[560px] w-[470px]" alt="uploaded"></img>
          )}
        </Modal>
      </div>
    </div>
  );
};
export default MyPage;
