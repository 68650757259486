import {
  createSearchParams,
  useNavigate,
  useParams,
  useRoutes,
  useSearchParams,
} from "react-router-dom";
import vid from "../../assets/images/HOST IMAGE/pr_pic1.jpg";
import vid1 from "../../assets/images/HOST IMAGE/pr_pic1 (1).jpg";
import vid2 from "../../assets/images/HOST IMAGE/pr_pic1 (2).jpg";
import vid3 from "../../assets/images/HOST IMAGE/pr_pic1 (3).jpg";
import vid4 from "../../assets/images/HOST IMAGE/pr_pic1 (4).jpg";
import vid5 from "../../assets/images/HOST IMAGE/pr_pic1 (5).jpg";
import vid6 from "../../assets/images/HOST IMAGE/pr_pic1 (6).jpg";
import vid7 from "../../assets/images/HOST IMAGE/pr_pic1 (7).jpg";
import vid8 from "../../assets/images/HOST IMAGE/pr_pic1 (8).jpg";
import vid9 from "../../assets/images/HOST IMAGE/pr_pic1 (9).jpg";
import * as cts from "../../constants/ui/svg";
import i18n from "locales/i18n";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import BreadcrumbComponent from "components/Common/Breadcrumb";
import { useAppSelector } from "app/hooks";
import { Tabs } from "antd";
import { FaChevronDown } from "react-icons/fa";

const RegionPage = () => {
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  let { params } = useParams();
  const [sort, setSort] = useState(i18n.t("NATIONWIDE"));
  const [visibleSortOption, setVisibleSortOption] = useState(false);
  const channelsSt = useAppSelector((state) => state.channels.channels);

  const arr = [
    {
      id: 1,
      img: vid1,
      ava: vid9,
      title: "動画のタイトル。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するため。",
      name: "ニックネーム",
    },
    {
      id: 2,
      img: vid2,
      ava: vid8,
      title: "動画のタイトル。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するため。",
      name: "ニックネーム",
    },
    {
      id: 3,
      img: vid3,
      ava: vid7,
      title: "動画のタイトル。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するため。",
      name: "ニックネーム",
    },
    {
      id: 4,
      img: vid4,
      ava: vid6,
      title: "動画のタイトル。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するため。",
      name: "ニックネーム",
    },
    {
      id: 5,
      img: vid5,
      ava: vid,
      title: "動画のタイトル。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するため。",
      name: "ニックネーム",
    },
    {
      id: 6,
      img: vid6,
      ava: vid5,
      title: "動画のタイトル。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するため。",
      name: "ニックネーム",
    },
    {
      id: 7,
      img: vid7,
      ava: vid4,
      title: "動画のタイトル。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するため。",
      name: "ニックネーム",
    },
    {
      id: 8,
      img: vid8,
      ava: vid3,
      title: "動画のタイトル。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するため。",
      name: "ニックネーム",
    },
    {
      id: 9,
      img: vid9,
      ava: vid2,
      title: "動画のタイトル。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するため。",
      name: "ニックネーム",
    },
    {
      id: 10,
      img: vid,
      ava: vid1,
      title: "動画のタイトル。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するため。",
      name: "ニックネーム",
    },
    {
      id: 11,
      img: vid2,
      ava: vid9,
      title: "動画のタイトル。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するため。",
      name: "ニックネーム",
    },
    {
      id: 12,
      img: vid3,
      ava: vid8,
      title: "動画のタイトル。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するため。",
      name: "ニックネーム",
    },
  ];
  const arrLink = [
    { id: 0, name: i18n.t("NATION_WIDE") },
    { id: 1, name: i18n.t("REGION1") },
    { id: 2, name: i18n.t("REGION2") },
    { id: 3, name: i18n.t("REGION3") },
    { id: 4, name: i18n.t("REGION4") },
    { id: 5, name: i18n.t("REGION5") },
    { id: 6, name: i18n.t("REGION6") },
    { id: 7, name: i18n.t("REGION7") },
  ];
  const sortOptions = [
    { name: i18n.t("NATIONWIDE") },
    { name: i18n.t("HOKKAIDO") },
    { name: i18n.t("TOHOKU") },
    { name: i18n.t("KANTO") },
    { name: i18n.t("CHUBU") },
    { name: i18n.t("KINKI") },
    { name: i18n.t("CHINA/SHIKOKU") },
    { name: i18n.t("KYUSHU/OKINAWA") },
  ];
  const nameRegion = searchparams.get("area");

  const _renderChildren = () => {
    return (
      <div className="grid grid-cols-4 gap-4 mt-[20px] mb-[40px] max-500px:mt-3 max-500px:grid-cols-2 max-500px:gap-3">
        {channelsSt?.map((x: any, y: number) => {
          return (
            <div
              key={y}
              className="flex flex-col cursor-pointer hover:shadow-[0px_4px_8px_-2px_rgba(0,0,0,0.4)] rounded-[8px] w-[45vw] h-[45vw]"
            >
              <>
                <div
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate({
                      pathname: "/livestream",
                      search: createSearchParams({
                        key: `${x.user?._id}`,
                      }).toString(),
                    });
                  }}
                  className="relative h-[200px] max-500px:h-full max-500px:relative"
                >
                  <div className="flex items-center justify-between mt-[8px] mx-[8px] absolute w-11/12">
                    <span>{cts.live1Icon}</span>
                    <div className="bg-[#182230] rounded-[4px] flex items-center justify-center h-[20px] w-[53px] max-500px:bg-[#FCFCFDB2]">
                      <span className="sz12_view">{cts.view1Icon}</span>
                      <span className="text-[#FFFFFF] text-[12px] notosans_normal ml-[5px] max-500px:text-[#101828]">
                        2023
                      </span>
                    </div>
                  </div>
                  <img
                    className="w-full h-full rounded-t-[8px] object-cover max-500px:rounded-lg "
                    src={vid}
                  />

                  <div className=" hidden p-[12px] max-500px:flex flex-col  rounded-b-[8px] absolute left-0 right-0 bottom-0  bg_linear ">
                    <span className="text-[#101828] text-[14px] notosans_bold text_detail  max-500px:text-white max-500px:line-clamp-1">
                      動画のタイトル。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するため。
                    </span>
                    <div
                      onClick={() => {
                        window.scrollTo(0, 0);

                        navigate({
                          pathname: "/streamer-profile",
                          search: createSearchParams({
                            key: `${x._id}`,
                          }).toString(),
                        });
                      }}
                      className="flex items-center mt-[12px] cursor-pointer max-500px:mt-1"
                    >
                      <img
                        className="h-[30px] w-[30px] rounded-lg object-cover max-500px:h-4 max-500px:w-4"
                        src={vid2}
                      />
                      <span className="text-[#101828] text-[12px] notosans_normal ml-[8px]  max-500px:text-white">
                        {x?.user?.nickName}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="p-[12px] flex flex-col bg-[#FCFCFD] rounded-b-[8px] max-500px:hidden">
                  <span className="text-[#101828] text-[14px] notosans_bold text_detail  max-500px:text-white">
                    動画のタイトル。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するため。
                  </span>
                  <div
                    onClick={() => {
                      window.scrollTo(0, 0);

                      navigate({
                        pathname: "/streamer-profile",
                        search: createSearchParams({
                          key: `${x._id}`,
                        }).toString(),
                      });
                    }}
                    className="flex items-center mt-[12px] cursor-pointer"
                  >
                    <img className="h-[30px] w-[30px] rounded-lg object-cover" src={vid2} />
                    <span className="text-[#101828] text-[12px] notosans_normal ml-[8px]  max-500px:text-white">
                      {x?.user?.nickName}
                    </span>
                  </div>
                </div>
              </>
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div className="flex pt-[10px] pb-[40px] mt-[2px] justify-center">
      <div className="flex-col flex size_content_hd w-full ">
        <div className=" max-500px:hidden">
          <BreadcrumbComponent breadMain={i18n.t("REGION")} />
        </div>

        <div className="mt-[10px] mb-3 flex justify-between items-center">
          <span className="text-[#101828] mt-[10px] text-[24px] notosans_bold max-500px:text-lg max-500px:font-bold">
            {i18n.t("REGION")}
          </span>
          <div className="flex justify-end relative max-500px:hidden">
            <button
              onClick={() => setVisibleSortOption((x) => !x)}
              className="border border-[#D0D5DD] bg-white flex items-center justify-between rounded-lg px-3 w-[140px] py-2 "
            >
              <span className="text-[#667085] text-base notosans_normal ">{sort}</span>
              <FaChevronDown color="#EF6820" size={12} />
            </button>
            {visibleSortOption && (
              <div
                onBlur={() => setVisibleSortOption(false)}
                className="absolute top-11 right-0  bg-white  flex-col gap-2 rounded-lg border border-[#EAECF0] p-2 w-[140px] flex"
              >
                {sortOptions.map((option: any, index: any) => {
                  return (
                    <button
                      onClick={() => {
                        setSort(option.name);
                        setVisibleSortOption(false);
                      }}
                      key={index}
                      className="w-full flex items-center rounded-lg hover:bg-[#FEF6EE] notosans_normal text-base py-2 px-3"
                    >
                      {option.name}
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        {/* <div className=" pt-[20px] grid grid-cols-8 gap-4 max-1200px:grid-cols-4 max-500px:hidden">
        {arrLink?.map((x, y) => {
          return (
            <div
              key={y}
              // onClick={() => {
              //   navigate({
              //     pathname: "/region",
              //     search: createSearchParams({
              //       area: `${x.name}`,
              //     }).toString(),
              //   });
              //   // navigate(`/region?area=${x.name}`);
              // }}
              className="border bg-white border-[#EF6820] cursor-pointer h-[50px] w-full rounded-[12px] flex items-center justify-center"
            >
              <span className="text-[#101828] text_1_line max-1538px:text-[12px] notosans_bold">
                {x.name}
              </span>
            </div>
          );
        })}
      </div> */}

        <div className="hidden max-500px:flex region_tabs">
          <Tabs
            defaultActiveKey="0"
            style={{ width: "100%", overflowX: "scroll", display: "flex" }}
            items={arrLink.map((item) => {
              return {
                label: item.name,
                key: String(item.id),
                children: _renderChildren(),
              };
            })}
          />
          {/* {arrLink.map((item) => {
          return (
            <button key={item.id} className="w-[130px]">
              {item.name}
            </button>
          );
        })} */}
        </div>
        <div className="grid max-1040px:grid-cols-2 grid-cols-4 gap-4 pt-[20px] pb-[40px] max-500px:hidden">
          {channelsSt?.map((x: any, y: any) => {
            return (
              <div
                key={y}
                className="flex flex-col cursor-pointer hover:shadow-[0px_4px_8px_-2px_rgba(0,0,0,0.4)] rounded-[8px]"
              >
                <>
                  <div
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate({
                        pathname: "/livestream",
                        search: createSearchParams({
                          key: `${x.user?._id}`,
                        }).toString(),
                      });
                    }}
                    className="relative h-[200px]"
                  >
                    <div className="flex items-center justify-between mt-[8px] mx-[8px] absolute w-11/12">
                      <span>{cts.live1Icon}</span>
                      <div className="bg-[#182230] rounded-[4px] flex items-center justify-center h-[20px] w-[53px]">
                        <span className="sz12_view">{cts.view1Icon}</span>
                        <span className="text-[#FFFFFF] text-[12px] notosans_normal ml-[5px]">
                          2023
                        </span>
                      </div>
                    </div>
                    <img className=" rounded-t-[8px] object-cover w-full h-full" src={vid} />
                  </div>
                  <div className="p-[12px] flex flex-col bg-[#FCFCFD] rounded-b-[8px]">
                    <span className="text-[#101828] text-[14px] notosans_bold text_detail">
                      動画のタイトル。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するため。
                    </span>
                    <div
                      onClick={() => {
                        window.scrollTo(0, 0);
                        navigate({
                          pathname: "/streamer-profile",
                          search: createSearchParams({
                            key: `${x.user?._id}`,
                          }).toString(),
                        });
                      }}
                      className="flex items-center mt-[12px] cursor-pointer"
                    >
                      <img className="h-[30px] w-[30px] rounded-lg object-cover" src={vid2} />
                      <span className="text-[#101828] text-[12px] notosans_normal ml-[8px]">
                        {x.user.nickName}
                      </span>
                    </div>
                  </div>
                </>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default RegionPage;
