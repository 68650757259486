// import { useRef, useState, useEffect } from "react";
import ReactJWPlayer from "react-jw-player";
// import playerFunction from "../../utils/player";
// import ReactJWPlayer from "@jwplayer/jwplayer-react";
import { useContainerDimensions } from "helpers";
import ReactHlsPlayer from "react-hls-player";

import React, { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { useNavigate } from "react-router-dom";
import { getToken } from "helpers/Token";
import { useAppDispatch } from "app/hooks";
import { Modal } from "antd";
import liveIcon from "../../assets/images/liveIcon.png";

const Video = (props) => {
  const videoNode = useRef(null);
  const [player, setPlayer] = useState(null);
  const dispatch = useAppDispatch();
  const token = getToken();

  useEffect(() => {
    if (videoNode.current) {
      const _player = videojs(videoNode.current, props);
      setPlayer(_player);
      return () => {
        if (player !== null) {
          player.dispose();
        }
      };
    }
  }, []);

  return (
    <div data-vjs-player>
      <video ref={videoNode} className="video-js w-full h-screen"></video>
    </div>
  );
};

export function JWPlayer(props) {
  const { url, thumbnail, className } = props;
  const componentRef = useRef();
  const { width, height } = useContainerDimensions(componentRef);
  const navigate = useNavigate();
  const [visibleModal, setVisibleModal] = useState(false);
  const [urlPlayer, setUrlPlayer] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [failed, setFailed] = useState();
  const __renderVideoJs = () => {
    const play = {
      fill: true,
      fluid: true,
      autoplay: true,
      controls: true,
      // preload: "auto",
      sources: [
        {
          src: urlPlayer,
          type: "application/x-mpegURL",
        },
      ],
      html5: {
        hls: {
          enableLowInitialPlaylist: true,
          smoothQualityChange: true,
          overrideNative: true,
        },
      },
      currentWidth: width,
      height: height,
    };

    return (
      <div ref={componentRef} className="player-live h-screen">
        {urlPlayer && <Video {...play} />}
      </div>
    );
  };
  useEffect(() => {
    if (failed?.type === "error") {
      // errorToast("配信が終わりました");
      setVisibleModal(true);
      // setTimeout(() => {
      //   navigate("/");
      //   window.location.reload();
      // }, 3000);
    }
  }, [failed]);
  const __renderJWPlayer = () => {
    return (
      <>
        {width && height && (
          <ReactJWPlayer
            isAutoPlay={true}
            playerId="asd45r346512dfg45"
            // playerScript="https://content.jwplatform.com/libraries/tqjyvT9W.js"
            // playerScript={playerFunction}
            // library="https://content.jwplatform.com/libraries/IDzF9Zmk.js"
            playerScript="https://content.jwplatform.com/libraries/IDzF9Zmk.js"
            // file={`https://live.freetemplates.xyz:1935/live/aube/playlist.m3u8`}
            file={urlPlayer}
            // image={thumbnail ? thumbnail : ""}
            // playlist={playlist}
            // width={width}
            // height={height}
            backgroundColor="white"
            isMuted={false}
            onError={(e) => {
              console.log("error player", e);
              setFailed(e);
            }}
          />
        )}
        <Modal
          centered
          open={visibleModal}
          onOk={() => setVisibleModal(false)}
          onCancel={() => setVisibleModal(false)}
          className="modal_confirm_error"
        >
          <div className="flex flex-col justify-center items-center">
            <img className="h-[50px] w-[195px]" src={liveIcon} />
            <span className="text-[32px] notosans_bold mt-[30px]">配信が終わりました</span>
          </div>
        </Modal>
      </>
    );
  };

  const playerRef = useRef(null);
  const buttonRef = useRef(null);

  const playVideo = () => playerRef.current.play();
  const pauseVideo = () => playerRef.current.pause();

  const __renderReactHlsPlayer = () => {
    return (
      <div ref={componentRef} className="player-live h-[100%]">
        <ReactHlsPlayer
          playerRef={playerRef}
          src={urlPlayer}
          autoPlay={true}
          controls={true}
          width={width}
          height={height}
          onLoadedData={() => setLoaded(true)}
          hlsConfig={{
            maxLoadingDelay: 4,
            minAutoBitrate: 0,
            lowLatencyMode: true,
          }}
        />
      </div>
    );
  };

  useEffect(() => {
    if (url) {
      setUrlPlayer(url);
      // buttonRef.current.click();
    }
  }, [url]);
  return (
    <div ref={componentRef} className="player-live h-[100%]">
      {__renderJWPlayer()}
      {/* <button ref={buttonRef} onClick={playVideo}>
        Play
      </button>
      <button onClick={pauseVideo}>Pause</button> */}
    </div>
  );
}
