import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  chats: <any>[],
  userInfo: <any>{}
};

const chatsSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    fetchChats(state, action) {},
    fetchChatsSuccess(state, action) {
      state.chats = action.payload;
    },

    createChatSuccess(state, action) {
      if (action.payload?.localData) {
        state.userInfo = action.payload?.sender
        state.chats = [...state.chats, action.payload];
      }

      if (state.userInfo?._id !== action.payload?.sender?._id) {
        state.chats = [...state.chats, action.payload];
      }

    },
  },
});

// Action
export const { fetchChats, fetchChatsSuccess, createChatSuccess } = chatsSlice.actions;

// Reducer
const historyPlanReducer = chatsSlice.reducer;

export default historyPlanReducer;
