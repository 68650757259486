import React, { useCallback, useState, useEffect, memo, useRef } from "react";
import { Button, Input } from "antd";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import i18n from "locales/i18n";
import * as cts from "../../../constants/ui/svg";
import { URL_UPLOAD_IMAGE, SOCKET_SERVER } from "../../../constants";
import { useAppDispatch, useAppSelector } from "app/hooks";
import noAva from "../../../assets/images/noAva.png";
import ava8 from "../../../assets/images/HOST IMAGE/pic01.jpg";
import { JWPlayer } from "../../../components/Common/JWPlayer";
import { fetchChats } from "slices/chatSlice";
import { getUserInfo } from "slices/userSlice";
import { changeFollow } from "slices/followSlice";
import { errorToast, formatMoney } from "helpers";
import EmojiPicker from "emoji-picker-react";

interface Props {
  width: number;
  changeName: any;
  arrGift: any;
  isShowChat: boolean;
  joined: any;
  messagesEndRef: any;
  liveStream: any;
  sendChatMessage: (valueChat: any) => void;

  openBox: boolean;
  setOpenBox: React.Dispatch<React.SetStateAction<boolean>>;
  // valueChat: any;
  // setValueChat: any;
  selectGift: any;
  setSelectGift: React.Dispatch<React.SetStateAction<any>>;
  clickHeart: any;
  setClickHeart: React.Dispatch<React.SetStateAction<boolean>>;
}

const BoxChatComponent: React.FC<Props> = (props: Props) => {
  const {
    width,
    changeName,
    arrGift,
    isShowChat,
    joined,
    sendChatMessage,
    openBox,
    setOpenBox,
    // valueChat,
    // setValueChat,
    selectGift,
    setSelectGift,
    clickHeart,
    setClickHeart,
  } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchparams] = useSearchParams();
  const key = searchparams.get("key");
  const pointCurren: any = localStorage.getItem("getUserInfo") || 0;
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const detailHost = useAppSelector((state) => state.follow.detailHost);
  const listFollow = useAppSelector((state) => state.follow.listFollowInStream);
  const chats = useAppSelector((state) => state.chats.chats);
  const userInfo = useAppSelector((state) => state.users.userInfo);
  const liveStream = useAppSelector((state) => state.liveStream.liveStream);

  const [isShowInfo, setIsShowInfo] = useState(true);
  // const [openBox, setOpenBox] = useState(false);
  const [valueChat, setValueChat] = useState<any>({});
  // const [selectGift, setSelectGift] = useState({ id: "", coin: 0, image: "", push: false });
  // const [clickHeart, setClickHeart] = useState(false);
  const [emojiPicker, setEmojiPicker] = useState(false);

  const __renderGift = (item: any) => {
    return (
      <div className="max-800px:w-[50%] max-800px:flex max-800px:items-center">
        <div className="bg-[#F38744] max-800px:bg-[#1018284D] max-800px:p-[5px] max-800px:w-full  max-800px:rounded-[25px] max-800px:items-center max-800px:mx-[0px] mx-[16px] mb-[12px] rounded-[10px] p-[12px] flex justify-between items-start">
          <div className="flex items-center">
            <img
              className="h-[36px] w-[36px] max-800px:h-[40px] max-800px:w-[40px] rounded-[50%] mr-[8px] object-cover"
              src={
                item?.sender?.avatar ? `${URL_UPLOAD_IMAGE}/avatars/${item?.sender?.avatar}` : noAva
              }
            />
            <div className="flex flex-col">
              <span className="text-white text-[14px] max-800px:text-[13px] max-800px:!notosans_bold notosans_normal">
                {item?.sender?.nickName}
              </span>
              <span className="flex items-center max-800px:hidden text-white text-[14px] max-800px:text-[13px] notosans_normal">
                {formatMoney(item?.coins)}
                {cts.coinIcon1}
              </span>
            </div>
          </div>
          <img
            className="h-[60px] max-800px:h-[45px] max-800px:w-[45px] w-[60px] rounded-[50%] object-contain"
            src={`${URL_UPLOAD_IMAGE}/gifts/${item?.message}`}
          />
        </div>
        <span className="flex items-center min-800px:hidden text-white text-[15px] ml-[5px] notosans_normal">
          {formatMoney(item?.coins)}
          {cts.coinIcon1}
        </span>
      </div>
    );
  };

  //   if (clickHeart) {
  //     const data = {
  //       sender: userInfo,
  //       receiver: key,
  //       room: key,
  //       message: clickHeart,
  //       coins: selectGift?.coin || 0,
  //       gift: selectGift?.id || null,
  //       nickName: changeName || null,
  //     };
  //     if (joined) {
  //       // const socket: any = io(`${SOCKET_SERVER}/realtime`);

  //       io(`${SOCKET_SERVER}/realtime`).emit("chatToServer", data);
  //     }
  //   }
  //   // if (data) {
  //   const timeout = setTimeout(() => {
  //     setClickHeart(false);
  //   }, 800);
  //   return () => clearTimeout(timeout);
  // }, [clickHeart]);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chats?.length]);
  useEffect(() => {
    if (key) {
      dispatch(fetchChats({ receiver: key }));
    }
  }, []);
  return (
    <>
      <div
        className={`${isShowChat === false && "hidden"}
    flex justify-start relative pt-[8px] bg-white h-full flex-col box_chat overflow-y-auto`}
      >
        {userInfo?._id &&
          chats
            ?.filter(
              (x: any) =>
                (x?.sender?._id === userInfo?._id || x?.sender?._id === key) &&
                x?.status !== "blocked" &&
                x?.message !== "true"
            )
            ?.map((x: any, y: any) => {
              return (
                <>
                  {x?.coins > 0 ? (
                    __renderGift(x)
                  ) : (
                    <div key={y} className="flex pb-[12px] px-[16px] items-center">
                      <img
                        className="h-[24px] w-[24px] rounded-[50%] mr-[8px] object-cover"
                        src={
                          x?.sender?.avatar
                            ? `${URL_UPLOAD_IMAGE}/avatars/${x?.sender?.avatar}`
                            : noAva
                        }
                      />
                      <div>
                        <span
                          // onClick={() => setFollow((prev) => !prev)}
                          className={`${
                            x.coin ? "text-[#EF6820]" : "text-[#797983]"
                          } text-[13px] notosans_normal mr-[8px]`}
                        >
                          {x?.sender?.nickName}
                        </span>
                        <span className="text-[13px] notosans_normal">{x?.message}</span>
                      </div>
                    </div>
                  )}
                </>
              );
            })}

        <div ref={messagesEndRef} />
      </div>
      {/* <HeartFly /> */}

      <div className="w-full flex justify-end">
        {clickHeart && (
          <>
            <span className={`heart mt-[-70px] mr-[15px] btn-reaction absolute pb-[100px]`}>
              {cts.reactionLive}
            </span>
            <span className={`heart1 mt-[-80px] mr-[15px] btn-reaction absolute pb-[80px]`}>
              {cts.reactionLive}
            </span>
            <span className={`heart2 mt-[-70px] mr-[15px] btn-reaction absolute pb-[60px]`}>
              {cts.reactionLive}
            </span>
            <span className={`heart3 mt-[-80px] mr-[15px] btn-reaction absolute pb-[40px]`}>
              {cts.reactionLive}
            </span>
          </>
        )}

        {userInfo?._id && !openBox && (
          <>
            <Button
              onClick={() => {
                setClickHeart(true);
                sendChatMessage(valueChat.chat);
              }}
              className={`${
                isShowChat === false && "hidden"
              } icon-heart border-none focus:outline-none focus:border-none hover:!border-none p-0 absolute mt-[-60px] mr-[10px] btn-reaction flex justify-center items-center`}
            >
              {cts.reactionLive}
            </Button>
          </>
        )}
      </div>
      {/* </div> */}

      {openBox && (
        <>
          <div
            style={{ boxShadow: " 0px 2px 2px rgba(0,0,0,0.1) inset" }}
            className="flex items-center justify-between bg-white pt-[12px] px-[12px]"
          >
            <div className="flex items-center">
              <span className="text-[#101828] text-[20px] notosans_bold mr-[12px]">
                {i18n.t("SUPPORT")}
              </span>
              <img
                className="h-[20px] w-[20px] rounded-[50%] mr-[8px] object-cover"
                src={userInfo?.avatar ? `${URL_UPLOAD_IMAGE}/avatars/${userInfo?.avatar}` : noAva}
              />
              <span className="text-[#101828] text-[20px] notosans_bold">
                {changeName !== "" ? changeName : userInfo?.nickName}
              </span>
            </div>
            <Button
              onClick={() => {
                setOpenBox(false);
                setSelectGift({ id: "", coin: 0, image: "", push: true });
              }}
              className="border-none shadow-none p-0"
            >
              {cts.close1Icon}
            </Button>
          </div>
          <div className="flex items-center bg-white pb-[12px] px-[12px] justify-between">
            <span className="text-[#101828] mr-[8px] text-[14px] notosans_normal text_1_line">
              {i18n.t("CURRENT_POINT")}
            </span>
            <span className="text-[#101828] text-[16px] notosans_bold mr-[2px]">
              {formatMoney(Number(pointCurren)) || 0}
            </span>
            <span>{cts.coinIcon1}</span>
            <div
              className="bg-[#EF6820] px-[16px] flex items-center text_1_line h-[32px] rounded-[8px] cursor-pointer text-white ml-[16px] notosans_medium text-[13px]"
              onClick={() => {
                window.scrollTo(0, 0);
                navigate(
                  {
                    pathname: "/purchase-point",
                  },
                  {
                    state: {
                      prevPath: detailHost?.nickName,
                    },
                  }
                );
                localStorage.setItem("buyCoin", "buyCoin");
              }}
            >
              <span className="text_1_line">{i18n.t("PURCHASE_POINT")}</span>
            </div>
            {/* <Link
          onClick={() => localStorage.setItem("buyCoin", "buyCoin")}
          className="bg-[#EF6820] px-[16px] flex items-center text_1_line h-[32px] rounded-[8px] cursor-pointer text-white ml-[16px] notosans_medium text-[13px]"
          to="/purchase-point"
        >
          <span className="text_1_line">{i18n.t("PURCHASE_POINT")}</span>
        </Link> */}
          </div>
          <div
            style={{ boxShadow: "0px 2px rgba(0,0,0,0.1)" }}
            className="h-full bg-white overflow-y-auto rank_scroll px-[12px] pb-[12px]"
          >
            <div className="overflow-y-auto mt-[12px] grid grid-cols-4 gap-4">
              {arrGift.map((x: any, y: number) => {
                return (
                  <div
                    onClick={() => {
                      // setSelectGift({ push: false, coin: x.coin, gift: x.gift });
                      if (selectGift?.coin === x?.coin && selectGift?.image === x?.image) {
                        setSelectGift({ id: x?._id, push: false, coin: 0, image: "" });
                      } else {
                        setSelectGift({
                          id: x?._id,
                          push: false,
                          coin: x.coin,
                          image: x.image,
                        });
                      }
                    }}
                    key={y}
                    className={`${
                      selectGift?.image === x?.image && "bg-[#FEF6EE]"
                    } hover:bg-[#FEF6EE] cursor-pointer py-[8px] rounded-[8px] flex flex-col items-center justify-center`}
                  >
                    <img
                      src={`${URL_UPLOAD_IMAGE}/gifts/${x?.image}`}
                      className="w-[48px] h-[48px] object-contain"
                    />
                    <span className=" flex items-center">
                      {formatMoney(x.coin)} {cts.coinIcon1}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="bg-white w-full flex justify-end pr-[12px] pb-[12px] pt-[8px]">
            <Button
              onClick={() => {
                if (selectGift?.coin > 0 && selectGift?.image) {
                  sendChatMessage(valueChat.chat);
                  //   setArrNew([...arrNew, valueChat]);
                  setValueChat({});
                  setOpenBox(false);
                }
              }}
              className="bg-[#EF6820] w-fit px-[20px] rounded-[8px] flex items-center justify-center h-[34px] text-white text-[14px] notosans_medium border-none hover:!text-white hover:!bg-[#EF6820]"
            >
              {i18n.t("GIVE")}
            </Button>
          </div>
        </>
      )}
      {emojiPicker && (
        <div
          // onBlur={() => setEmojiPicker(false)}
          // onFocus={() => setEmojiPicker(true)}
          className="h-[60%] emoji_picker"
        >
          <EmojiPicker
            // searchDisabled="true"
            previewConfig={{ showPreview: false }}
            // emojiStyle="google"
            onEmojiClick={(e) =>
              setValueChat({
                id: Math.random(),
                ava: ava8,
                name: userInfo?.nickName,
                chat: valueChat.chat ? valueChat.chat + e.emoji : e.emoji,
              })
            }
            height="100%"
            width="100%"
          />
        </div>
      )}
      {userInfo?._id && !openBox && (
        <div
          className={`${
            isShowChat === false && "hidden"
          } rounded-b-[12px] flex items-center border-[#D0D5DD] border-t`}
        >
          <Input
            value={valueChat.chat}
            onChange={(e) =>
              setValueChat({
                id: Math.random(),
                ava: ava8,
                name: userInfo.nickName,
                chat: e.target.value,
              })
            }
            onPressEnter={() => {
              if (Object.keys(valueChat).length !== 0 && valueChat?.chat !== "") {
                sendChatMessage(valueChat.chat);
                // setArrNew([...arrNew, valueChat]);
                setValueChat({});
              }
            }}
            className="h-full border-none rounded-bl-[12px] inp_chat"
            placeholder="メッセージを送信"
            suffix={
              <div className="flex items-center">
                {!emojiPicker ? (
                  <Button
                    onClick={() => {
                      setEmojiPicker((prev) => !prev);
                      setOpenBox(false);
                    }}
                    className="border-none shadow-none h-[20px] w-[20px] p-0 mr-[15px]"
                  >
                    {cts.happyIcon}
                  </Button>
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        setEmojiPicker((prev) => !prev);
                        if (openBox) {
                          setOpenBox(false);
                        }
                      }}
                      className="border-none shadow-none h-[20px] w-[20px] p-0 mr-[15px]"
                    >
                      {cts.happyIcon}
                    </Button>
                  </>
                )}

                <Button
                  onClick={() => {
                    setOpenBox((prev: any) => !prev);
                    if (emojiPicker) {
                      setEmojiPicker(false);
                    }
                  }}
                  className="border-none shadow-none p-0"
                >
                  {cts.giftBox}
                </Button>
              </div>
            }
          />
          <div
            onClick={() => {
              setSelectGift({ ...selectGift, push: true });
              localStorage.setItem(
                "getUserInfo",
                String(Number(userInfo?.points) - Number(selectGift?.coin) || 0)
              );
              setOpenBox(false);
              setEmojiPicker(false);
              if (
                (Object.keys(valueChat).length !== 0 && valueChat?.chat !== "") ||
                (selectGift?.coin > 0 && selectGift?.image)
              ) {
                sendChatMessage(valueChat.chat);
                // setArrNew([...arrNew, valueChat]);
                setValueChat({});
              }
            }}
            className=" h-[46px] cursor-pointer w-[56px] flex items-center justify-center rounded-br-[12px] text-white text-[14px] notosans_medium bg-[#EF6820]"
          >
            {cts.sendMess}
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(BoxChatComponent);
